/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-await-in-loop */

import React, { useRef, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

// TODO: use react-konva https://codesandbox.io/s/github/konvajs/site/tree/master/react-demos/basic_demo?from-embed

interface CanvasProps {
  width?: number;
  height?: number;
  firstSetter?: any;
  secondSetter?: any;
  thirdSetter?: any;
  fourthSetter?: any;
  defaultFirst: number;
  defaultSecond: number;
  defaultThird: number;
  defaultFourth: number;
  mode: number;
  disabled: boolean;
  hidden?: boolean;
}

const servoBody = new Image();
servoBody.src = "/servo_body.png";

const servo = new Image();
servo.src = "/servo_leg_single_green_num.png";

const servoRed = new Image();
servoRed.src = "/servo_leg_single_red_num.png";

const servoYellow = new Image();
servoYellow.src = "/servo_leg_single_yellow_num.png";

const servoBlue = new Image();
servoBlue.src = "/servo_leg_single_blue_4.png";


const Canvas = ({
  width,
  height,
  firstSetter,
  secondSetter,
  thirdSetter,
  fourthSetter,
  defaultFirst,
  defaultSecond,
  defaultThird,
  defaultFourth,
  mode,
  disabled,
  hidden,
}: CanvasProps) => {
  const [first, setFirst] = useState(defaultFirst);
  const [second, setSecond] = useState(defaultSecond);
  const [third, setThird] = useState(defaultThird);
  const [fourth, setFourth] = useState(defaultFourth);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const degreesMin = 0;
  const degreesMax = 180;

  const draw = (_first: number = first, _second: number = second, _third: number = third, _fourth: number = fourth) => {
    if (canvasRef.current) {
      const servoWidth = 209;
      const servoHeight = 107;
      const servoX = 80;
      const servoY = 60;
      const canvas = canvasRef.current;
      const translateX = 184;
      const translateY = 114;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.save();
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.translate(0, 0);
        ctx.rotate(0);

        ctx.drawImage(servoBody, servoX + 14, -62, 180, 350);

        ctx.font = "18px serif";
        ctx.fillText("180°", 25, 117);
        ctx.font = "18px serif";
        ctx.fillText("0°", 300, 117);

        // First servo position
        ctx.translate(translateX, translateY);
        ctx.rotate(((180 - _first) * 2 * Math.PI) / 360);
        ctx.translate(-translateX, -translateY);
        ctx.drawImage(servoRed, servoX, servoY, servoWidth, servoHeight);

        // Reset transformation
        ctx.translate(translateX, translateY);
        ctx.rotate((-(180 - _first) * 2 * Math.PI) / 360);
        ctx.translate(-translateX, -translateY);

        // Second servo position
        ctx.translate(translateX, translateY);
        ctx.rotate(((180 - _second) * 2 * Math.PI) / 360);
        ctx.translate(-translateX, -translateY);
        ctx.drawImage(servo, servoX, servoY, servoWidth, servoHeight);

          // Reset transformation
          ctx.translate(translateX, translateY);
          ctx.rotate((-(180 - _second) * 2 * Math.PI) / 360);
          ctx.translate(-translateX, -translateY);

        if ([2, 3, 7, 8, 9].includes(mode)) { // TODO
            // Third servo position
            ctx.translate(translateX, translateY);
            ctx.rotate(((180 - _third) * 2 * Math.PI) / 360);
            ctx.translate(-translateX, -translateY);
            ctx.drawImage(servoYellow, servoX, servoY, servoWidth, servoHeight);

            ctx.translate(translateX, translateY);
            ctx.rotate((-(180 - _third) * 2 * Math.PI) / 360);
            ctx.translate(-translateX, -translateY);
        }
        // eslint-disable-next-line no-underscore-dangle
        if ([8].includes(mode)) {
            // Fourth servo position
            ctx.translate(translateX, translateY);
            ctx.rotate(((180 - _fourth) * 2 * Math.PI) / 360);
            ctx.translate(-translateX, -translateY);
            ctx.drawImage(servoBlue, servoX, servoY, servoWidth, servoHeight);

            // Reset transformation
            ctx.translate(translateX, translateY);
            ctx.rotate((-(180 - _fourth) * 2 * Math.PI) / 360); // ?
            ctx.translate(-translateX, -translateY);

        }
        ctx.restore();
      }
    }
  };

  const onChange = (e: any) => {
    let val = Number(e.target.value);
    // let val = e.target.value || 0;

    if (val && val > degreesMax) {
      val = degreesMax;
    } else if (val && val < degreesMin) {
      val = degreesMin;
    }
    e.target.value = val;

    if (e.target.name === "first") {
      setFirst(val);
      firstSetter(val);
      draw(val, second, third, fourth);
    } else if (e.target.name === "second") {
      setSecond(val);
      secondSetter(val);
      draw(first, val, third, fourth);
    } else if (e.target.name === "third") {
      setThird(val);
      thirdSetter(val);
      draw(first, second, val, fourth);
    } else if (e.target.name === "fourth") {
      setFourth(val);
      fourthSetter(val);
      draw(first, second, third, val);
    }
  };

  // const sleep = (milliseconds: number) => {
  //     return new Promise(resolve => {
  //         setTimeout(resolve, milliseconds);
  //     });
  // }

  useEffect(() => {
    draw();
  }, []);

  return (
    <>
      <div className="input-container">
        <label className={hidden ? 'hidden' : ''}>Стартова позиція (градуси)</label>
        <p className={`hint ${hidden ? 'hidden' : ''}`}>Стартова позиція на яку сервопривід стає після рестарту, а також як стартова точка циклу програми для деяких режимів.</p>
        <InputGroup className="mb-3">
          <Form.Control
            id="first"
            className="input-field"
            name="first"
            type="number"
            min={degreesMin}
            max={degreesMax}
            defaultValue={defaultFirst}
            onChange={onChange}
            disabled={disabled}
            required
          />
        </InputGroup>
      </div>
      <div className="input-container">
        {mode === 1 && (<label className={hidden ? 'hidden' : ''}>Позиція активації (градуси)</label>)}
        {mode === 1 && (<p className={`hint ${hidden ? 'hidden' : ''}`}>Позиція сервопривіда для розблокування замка.</p>)}
        {mode !== 1 && (<label className={hidden ? 'hidden' : ''}>Перша активація (градуси)</label>)}
        {mode !== 1 && (<p className={`hint ${hidden ? 'hidden' : ''}`}>Позиція сервопривіда для розблокування першого замка.</p>)}
        <InputGroup className="mb-3">
          <Form.Control
            id="second"
            className="input-field"
            name="second"
            type="number"
            min={degreesMin}
            max={degreesMax}
            defaultValue={defaultSecond}
            onChange={onChange}
            disabled={disabled}
            required
          />
        </InputGroup>
      </div>
      {[2, 3, 7, 9].includes(mode) && (
        <div className="input-container">
            <label className={hidden ? 'hidden' : ''}>Друга активація (градуси)</label>
            <p className={`hint ${hidden ? 'hidden' : ''}`}>Позиція сервопривіда для розблокування другого замка.</p>
            <InputGroup className="mb-3">
            <Form.Control
                id="third"
                className="input-field"
                name="third"
                type="number"
                min={degreesMin}
                max={degreesMax}
                defaultValue={defaultThird}
                onChange={onChange}
                disabled={disabled}
                required
            />
            </InputGroup>
        </div>
      )}
      {[8].includes(mode) && (
        <div className="input-container">
            <label className={hidden ? 'hidden' : ''}>Третя активація (градуси)</label>
            <p className={`hint ${hidden ? 'hidden' : ''}`}>Позиція сервопривіда для розблокування третього замка.</p>
            <InputGroup className="mb-3">
            <Form.Control
                id="fourth"
                className="input-field"
                name="fourth"
                type="number"
                min={degreesMin}
                max={degreesMax}
                defaultValue={defaultFourth}
                onChange={onChange}
                disabled={disabled}
                required
            />
            </InputGroup>
        </div>
      )}
      <canvas ref={canvasRef} height={height} width={width} />
    </>
  );
};

Canvas.defaultProps = {
  width: window.innerWidth,
  height: window.innerHeight,
  firstSetter: () => {},
  secondSetter: () => {},
  thirdSetter: () => {},
  fourthSetter: () => {},
  hidden: false,
  // defaultFirst: 0,
  // defaultSecond: 45,
  // defaultThird: 90,
  // defaultFourth: 135,
};

export default Canvas;
