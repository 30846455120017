import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from "react-bootstrap/NavDropdown";
import { useEffectOnce } from "usehooks-ts";

import { checkIsLoggedIn, firmwareVersion } from "../util/common";

const Navigation = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffectOnce(() => {
    (async () => {
      setLoggedIn(await checkIsLoggedIn());
    })();
  });

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Nav.Link className="nav-logo" href="/">
          <Image className="bavovna-logo" src="/bavovna_team.png" />
          <Navbar.Brand href="/">Bavovna Firmware Generator</Navbar.Brand>
          {loggedIn && <Navbar.Text className="version-number">Версія прошивки: {firmwareVersion}</Navbar.Text>}
        </Nav.Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            {loggedIn === false && <Nav.Link href="/login">Login</Nav.Link>}
            {loggedIn && <Nav.Link href="/logout">Log out</Nav.Link>}
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
