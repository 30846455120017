/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { config, getAccessToken } from "../util/common";

export interface Log {
    timestamp: number;
    date_human: string;
    notes: string[];
}

const Notif = () => {
    const [hide, setHide] = useState(true);
    const [log, setLog] = useState<Log[]>([]);

    const hideNotif = () => {
        if (log.length > 0) {
            // Save the most recent timestamp to local storage
            localStorage.setItem('latestTimestamp', log[0].timestamp.toString());
        }
        setHide(true);
    }

    const getNotes = async () => {
        try {
            const response = await fetch(`${config.API_URL}/release_notes/`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    "Authorization": `Bearer ${getAccessToken()}`,
                },
            });
            const json = await response.json();
            const newLog = json.log;

            // Get the latest saved timestamp from local storage
            const savedTimestamp = localStorage.getItem('latestTimestamp');
            const latestTimestamp = savedTimestamp ? parseInt(savedTimestamp, 10) : 0;

            // Check if there is any log with a newer timestamp
            if (newLog.length > 0 && newLog[0].timestamp > latestTimestamp) {
                setHide(false);
            }

            setLog(newLog);
        } catch (err: any) {
            console.error(err);
            setHide(true);
        }
    }

    useEffect(() => {
        getNotes();
    }, []);

    if (log?.length === 0) {
        return null;
    }

    if (hide) {
        return (
            <Container>
                <Row>
                    <Col className="col-xs-1" align="center">
                        <button className="show-release-log" type='button' onClick={() => { setHide(false) }}>Список оновлень</button>
                    </Col>
                </Row>
            </Container>
        )
        // return null;
    }

    return(
        <Container className="release-notif">
            <Row>
                <Col>
                    <h2>Список оновлень:</h2>
                </Col>
            </Row>
            {log.map((item, index) => {return (
                <Row key={index}>
                    <Col>
                        <p>{item.date_human}</p>
                        <ul>
                            {item.notes.map((note, noteIndex) => {return (
                                <li key={noteIndex}>{note}</li>
                            )})}
                        </ul>
                    </Col>
                </Row>
            )})}
            <Row>
                <Col>
                    <Button className="notif-close-button" variant="warning" onClick={hideNotif}>OK</Button>
                </Col>
            </Row>
        </Container>
    );
}

export default Notif;


// import { useEffect, useState } from "react";
// import { Button, Col, Container, Row } from "react-bootstrap";
// import { config, getAccessToken } from "../util/common";

// export interface Log {
//     timestamp: number;
//     date_human: string;
//     notes: string[];
// }

// const Notif = () => {
//     const [hide, setHide] = useState(true);
//     const [log, setLog] = useState<Log[]>([]);

//     const hideNotif = () => {
//         setHide(!hide);
//     }

//     const getNotes = async () => {
//         try {
//         const response = await fetch(`${config.API_URL}/release_notes/`, {
//             method: 'GET',
//             headers: {
//             Accept: 'application/json',
//             'Content-type': 'application/json',
//             "Authorization": `Bearer ${getAccessToken()}`,
//             },
//         });
//         const json = await response.json();
//         setHide(false);
//         setLog(json.log)
//         } catch (err: any) {
//             console.error(err);
//             setHide(true);
//         }
//     }

//     useEffect(() => {
//         getNotes();
//     }, []);

//     if (hide) {
//         return null;
//     }

//     return(
//         <Container className="release-notif">
//             <Row>
//                 <Col>
//                     <h2>Список оновлень:</h2>
//                 </Col>
//             </Row>
//             {log.map((item) => {
//                 return (
//                     <Row>
//                         <Col>
//                             <p>{item.date_human}</p>
//                             <ul>
//                                 {item.notes.map(note => {
//                                     return (
//                                         <li>{note}</li>
//                                     )
//                                 })}
//                             </ul>
//                         </Col>
//                     </Row>
//                 )
//             })}
//             <Row>
//                 <Col>
//                 <Button className="notif-close-button" variant="warning" onClick={hideNotif}>OK</Button>
//                 </Col>
//             </Row>
//         </Container>
//     );
// }

// export default Notif;
