/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { useEffectOnce } from "usehooks-ts";
import { config, setSessionCookie } from "../util/common";

const Login = () => {
  const [errorMessages, setErrorMessages] = useState<any>({});
  // const [isSubmitted, setIsSubmitted] = useState(false);
  const [captchaBase64, setCaptchaBase64] = useState("");
  const [captchaChallenge, setCaptchaChallenge] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const renderErrorMessage = (name: any) => {
    return (
      name === errorMessages.name && (
        <div className="error">{errorMessages.message}</div>
      )
    );
  };

  const fetchCaptcha = async (clearErrors: boolean = true) => {
    try {
      const response = await fetch(`${config.API_URL}/captcha/`);
      const json = await response.json();
      setCaptchaBase64(json.base64);
      setCaptchaChallenge(json.challenge);
      if (clearErrors) {
        setErrorMessages({});
      }
    } catch (e) {
      setCaptchaBase64('');
      setCaptchaChallenge('');
      console.error(e);
      setErrorMessages({
        name: 'captcha',
        message: "Немає зв'язку зі сервером! 😭",
      });
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const response = await fetch(`${config.API_URL}/token/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
          captcha_value: captcha.toLowerCase(),
          captcha_challenge: captchaChallenge,
        }),
      });
      const json = await response.json();
      if (response.status !== 200) {
        setCaptcha('');
        fetchCaptcha(false);
        let errorDetail = json.detail;
        if (json.detail.includes('No active account found')) {
          errorDetail = 'Не знайдено жодного активного облікового запису з указаними даними';
        } else if (json.detail.includes('expired')) {
          errorDetail = 'Термін дії цієї капчі минув';
        } else if (json.detail.includes('Incorrect')) {
          errorDetail = 'Неправильне рішення капчі';
        }
        setErrorMessages({
          name: json.field,
          message: errorDetail,
        });
        return;
      }
      setCaptchaBase64("");
      setCaptchaChallenge("");
      setErrorMessages({});
      setSessionCookie(json.access, json.refresh);
      localStorage.removeItem("nato");
      window.location.href = "/";
    } catch (e) {
      console.error(e);
      setErrorMessages({
        name: 'captcha',
        message: "Немає зв'язку зі сервером! 😭",
      });
      throw e;
    }
  };

  const onChange = async (event: any) => {
    if (event.target.name === "captcha") {
      setCaptcha(event.target.value);
    } else if (event.target.name === "username") {
      setUsername(event.target.value);
    } else if (event.target.name === "password") {
      setPassword(event.target.value);
    }
  };

  useEffectOnce(() => {
    fetchCaptcha();
  });

  return (
    <Container>
      <Row md="6" className="justify-content-center">
        <Col md="6" className="justify-content-center">
          <div className="bavovna-form">
            <form onSubmit={handleSubmit}>
              <div className="input-container">
                <label htmlFor="login-username">Username </label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="login-username-addon1">
                    👤
                  </InputGroup.Text>
                  <Form.Control
                    id="login-username"
                    name="username"
                    placeholder="русні"
                    aria-label="Username"
                    aria-describedby="login-username-addon1"
                    onChange={onChange}
                    required
                  />
                </InputGroup>
                {renderErrorMessage("username")}
              </div>
              <div className="input-container">
                <label htmlFor="login-password">Password </label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="login-password-addon1" className='password-icon'>
                    🔑
                  </InputGroup.Text>
                  <Form.Control
                    id="login-password"
                    className="input-field"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="пизда"
                    aria-label="Password"
                    aria-describedby="login-password-addon1"
                    onChange={onChange}
                    required
                  />
                  <InputGroup.Text id="login-password-addon1" className='password-icon' onClick={() => { setShowPassword(!showPassword) }}>
                    👁
                  </InputGroup.Text>
                </InputGroup>
                {renderErrorMessage("password")}
              </div>
                <div className="input-container">
                  {captchaBase64 && (
                    <>
                      <img
                        className="captcha"
                        src={`data:image/png;base64,${captchaBase64}`}
                        alt="Captcha"
                      />
                      <label htmlFor="captcha">Captcha text: </label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="login-password-addon1">
                          🤖
                        </InputGroup.Text>
                        <Form.Control
                          id="captcha"
                          name="captcha"
                          className="input-field"
                          type="text"
                          placeholder=""
                          autoComplete="off"
                          aria-label="Password"
                          aria-describedby="login-password-addon1"
                          onChange={onChange}
                          value={captcha}
                          required
                        />
                      </InputGroup>
                    </>
                  )}
                  {renderErrorMessage("captcha")}
                </div>
              <Button type="submit" className="button-container">
                Log in
              </Button>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
