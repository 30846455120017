import Container from "react-bootstrap/Container";
import { useEffectOnce } from "usehooks-ts";
import { clearSessionCookie } from "../util/common";

const Logout = () => {
  useEffectOnce(() => {
    clearSessionCookie();
  });

  return (
    <Container>
      <p>You have been logged out!</p>
    </Container>
  );
};

export default Logout;
