import React, { useEffect, useState } from 'react';

const Snowflakes: React.FC = () => {
    const [isSnowing, setIsSnowing] = useState(false);

    useEffect(() => {
        const d = new Date();
        const month = d.getMonth();
        if (month === 10 || month === 11 || month === 12) {
            setIsSnowing(true);
        }
    }, [setIsSnowing]);

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {isSnowing && (
                <div className="snowflakes" aria-hidden="true">
                    <div className="snowflake">❅</div>
                    <div className="snowflake">❆</div>
                    <div className="snowflake">❅</div>
                    <div className="snowflake">❆</div>
                    <div className="snowflake">❅</div>
                    <div className="snowflake">❆</div>
                    <div className="snowflake">❅</div>
                    <div className="snowflake">❆</div>
                    <div className="snowflake">❅</div>
                    <div className="snowflake">❆</div>
                    <div className="snowflake">❅</div>
                    <div className="snowflake">❆</div>
                </div>
            )}
        </>
    );
};

export default Snowflakes;
